import Page from 'component/new_design/layout/Page';
import { SitesTable } from '../partial/tables/sites/SitesTable';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { linkHelper } from 'linkHelper';
import { Link } from 'react-router-dom';
import { RoleGuard } from 'component/base/RoleGuard';
import { useState } from 'react';
import { CreateSiteModal } from '../partial/CreateSite/CreateSiteModal';
import Plus from 'component/new_design/icons/Plus.svg?react';
import PhotoImage from 'component/new_design/icons/PhotoImage.svg?react';

export default function Sites() {
  const { t } = useTranslation();
  const [isCreateSiteOpen, setIsCreateSiteOpen] = useState(false);

  return (
    <>
      <Page>
        <Stack direction="row" justifyContent="space-between" mb={6}>
          <Typography variant="h1">{t('sites')}</Typography>
          <Stack direction="row" gap={2}>
            <Button
              variant="contained"
              color="tertiary"
              size="large"
              startIcon={<PhotoImage />}
              component={Link}
              to={linkHelper.newDesign.settings.siteTemplates.getLink()}
            >
              {t('site_templates')}
            </Button>
            <RoleGuard roles={['billing_admin']} type="block">
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Plus />}
                onClick={() => setIsCreateSiteOpen(true)}
              >
                {t('create_site')}
              </Button>
            </RoleGuard>
          </Stack>
        </Stack>

        <SitesTable enableSearch enablePagination />
      </Page>

      {isCreateSiteOpen ? <CreateSiteModal onCancel={() => setIsCreateSiteOpen(false)} /> : null}
    </>
  );
}
